import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginErrorComponent } from './login-error/login-error.component';
import { ResultCustomerComponent } from '../account-management/result-customer/result-customer.component';
import { ConfirmationComponent } from '../subscription/confirmation/confirmation.component';
import { BodyComponent } from './body/body.component';
import { VehicleSubscriptionComponent } from '../subscription/vehicle-subscription/vehicle-subscription.component';
import { VehiclesListComponent } from '../subscription/vehicles-list/vehicles-list.component';
import { DisplayMessageComponent } from '../common/components/display-message/display-message.component';
import { RemoveConfirmationComponent } from '../subscription/remove-confirmation/remove-confirmation.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { GuidGuardService } from '../common/guardroutes/guidGuard.service';
import { VinGuardService } from '../common/guardroutes/vinGuard.service';
import { FaqsComponent } from '../common/components/faqs/faqs.component';
import { ReleaseNotesComponent } from '../common/components/release-notes/release-notes.component';
import { VehicleAssociationComponent } from '../subscription/vehicle-association/vehicle-association.component';
import { DownloadAppComponent } from '../subscription/download-app/download-app.component';

const routes: Routes = [
  {
    path: '',
    component: BodyComponent,
    children: [
      {
        path: '',
        component: DownloadAppComponent,
        outlet: 'search_bar'
      }    ]
  },
  {
    path: 'content',
    component: BodyComponent,
    children: [
      {
        path: '',
        component: DownloadAppComponent,
        outlet: 'search_bar'
      }    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
